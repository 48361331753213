import React from "react"
import { Link } from "gatsby";
import SEO from "../components/seo"
import "./../styles/success.scss";
//Icon
import { BiArrowBack } from "react-icons/bi";
import { FcCancel } from "react-icons/fc";

//SVG
import LogoWhite from "./../images/logo-white.svg";

const NotFoundPage = () => (
  <div className="succes-page">
    <SEO title="Error"/>
    <div className="message">
      <h1>Ooops!<FcCancel size="28" /> </h1>
      <span lang="es">Esta página no existe :(</span>
      <span lang="en">This page doesn't exist :(</span>
      <Link to="/" className="link"> <BiArrowBack size="18" /> Home </Link>
    </div>
    <img src={LogoWhite} className="img" />
  </div>
)

export default NotFoundPage
